import React from 'react';
import { withTheme } from 'styled-components';

import {
    StyledTimelineContainer,
    StyledTimelineMain,
    StyledTimelineNumberContainer,
    StyledTimelineNumber,
    StyledConnectingLine,
    StyledTextContainer,
    StyledHeading,
    StyledParagraphContainer
} from './Timeline.styled';
import { Text } from '@movember/mo-gel';

export interface TimelineProps {
    numberOfTimelineComponents: number;
    timelineHeadings: any;
    theme?: any;
}

export const Timeline = withTheme((props: TimelineProps) => {
    const { content } = props.theme.colors;
    const { timelineHeadings } = props;

    function createTimelineComponent(numberOfTimelineComponents: number) {
        const numberOfComponents = Array.from(Array(numberOfTimelineComponents).keys());
    
        const comps = numberOfComponents.map((index: number) => {
            const i = index + 1;
            return <StyledTimelineMain key={i} isFirst={i === 1}>
                <StyledTimelineNumberContainer>
                    <StyledTimelineNumber>
                        <Text tag="p" color="black" marginBottom="0">
                            {i}
                        </Text>
                    </StyledTimelineNumber>
                    <StyledConnectingLine isFirst={i === 1} isLast={i === numberOfTimelineComponents} />
                </StyledTimelineNumberContainer>
                <StyledTextContainer>
                    <StyledHeading level="h6" color={ content }>
                        { timelineHeadings[i - 1].heading }
                    </StyledHeading>
                    <StyledParagraphContainer as="p" display="block" color={ content }>
                        { timelineHeadings[i - 1].text }
                    </StyledParagraphContainer>
                </StyledTextContainer>
            </StyledTimelineMain>;
        });
    
        return comps;
    }
    
    return (
        <StyledTimelineContainer>
            {
                createTimelineComponent(props.numberOfTimelineComponents)
            }
        </StyledTimelineContainer>
    );
});