import React, { Component } from 'react';

import DiagonalSplitImage from '../../../images/page_banners/Banner_Athlete.png';
import DiagonalSplitImageTablet from '../../../images/page_banners/Banner_Athlete_Tablet.png';
import DiagonalSplitImageMobile from '../../../images/page_banners/Banner_Athlete_Mobile.png';

import { CallToAction } from '../../../typings/CallToAction.type';
import { DiagonalSplitBanner } from '../../../components/DiagonalSplitBanner/DiagonalSplitBanner';
import { StyledAthletePage } from './Athlete.styled';
import { Size } from '../../../typings/Size.type';
import { HalfImageHalfText } from '../../../components/HalfImageHalfText/HalfImageHalfText';
import HalfImage1 from '../../../images/athletes/Goalie.jpg';
import HalfImage2 from '../../../images/athletes/Athlete.jpg';
import HalfImage3 from '../../../images/athletes/Locker.jpg';

import { HowItWorks } from '../../../components/HowItWorks/HowItWorks';
import { AthletesHowItWorksContent } from '../../../contents/howItWorks';
import { withRouter } from 'react-router-dom';

class AthletePage extends Component<any> { 
  render() {

  const diagonalBannerCallToAction: CallToAction = {
    heading: 'ATHLETES',
    subHeading: `Breaking the Ice uses hockey to help young athletes gain the skills and knowledge that underpin good mental health and resilience.`,
    callToActionLabel: 'Sign Up',
    onClick: (): void => this.props.history.push('/contact')
  };

  const slides = [
    {
      image: HalfImage1,
      heading: 'HOW TO DEAL WITH THE TOUGH STUFF',
        // tslint:disable-next-line: max-line-length
      subHeading: `Sometimes hockey gets hard, and sometimes life gets harder. Learn a set of skills that will help you overcome the challenges in hockey and life.`
    },
    {
      image: HalfImage2,
      heading: 'HOW TO TELL IF A TEAM MATE IS STRUGGLING',
      // tslint:disable-next-line: max-line-length
      subHeading: `Make sure you have your teammate's back by understanding when they might be struggling, and how you can help.`
    },
    {
      image: HalfImage3,
      heading: 'HAVING IMPORTANT CONVERSATIONS',
      // tslint:disable-next-line: max-line-length
      subHeading: `Talking about mental health can be awkward. Learn how to start a conversation, and when to ask for help.`
    },
      
  ];

  return (
    <StyledAthletePage>
      <DiagonalSplitBanner callToAction={diagonalBannerCallToAction} images={[DiagonalSplitImage, DiagonalSplitImageTablet, DiagonalSplitImageMobile]} size={Size.small}/>
      <HalfImageHalfText slides={slides} />
      <HowItWorks numberOfTimelineComponents={3} content={AthletesHowItWorksContent} />
    </StyledAthletePage>
  );
  }
}

const Athlete = withRouter(AthletePage);

export { Athlete as AthletePage };