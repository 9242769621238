import React, { Component } from 'react'
import { CarouselNextArrowContainer, CarouselPrevArrowContainer, StyledIcon } from './CarouselArrow.styled'
import { ReactComponent as NextArrow } from '../../../images/icons/right_icon.svg'
import { ReactComponent as PrevArrow } from '../../../images/icons/left_icon.svg'


export class CarouselPrevArrow extends Component <{clickFunction: any}>{
    
    render() {
        return(
            <CarouselPrevArrowContainer
            onClick={this.props.clickFunction}>
                <StyledIcon>
                    <PrevArrow />
                </StyledIcon>
            </CarouselPrevArrowContainer>
        )
    }
}


export class CarouselNextArrow extends Component <{clickFunction: any}>{
    
    render() {
        return(
            <CarouselNextArrowContainer
            onClick={this.props.clickFunction}>
                <StyledIcon>
                    <NextArrow />
                </StyledIcon>
            </CarouselNextArrowContainer>
        )
    }
}
