import React, { Component } from 'react';

import DiagonalSplitImage from '../../images/research/Research_page-desktop.png';
import DiagonalSplitImageTablet from '../../images/research/Research_page-tablet.png';
import DiagonalSplitImageMobile from '../../images/research/Research_page-mobile.png';
import { StyledResearchPage } from './Research.styled';
import { DiagonalSplitBanner } from '../../components/DiagonalSplitBanner/DiagonalSplitBanner';
import { Size } from '../../typings/Size.type';
import { ResearchContent } from '../../contents/research';
import { TwoColumnTiles } from '../../components/TwoColumnTiles/TwoColumnTiles';

export class ResearchPage extends Component {
    render() {
        return (
        <StyledResearchPage>
            <DiagonalSplitBanner callToAction={ResearchContent} images={[DiagonalSplitImage, DiagonalSplitImageTablet, DiagonalSplitImageMobile]} size={Size.small} />
            <TwoColumnTiles/>
        </StyledResearchPage>
        );
    }
}