import React from 'react';
import { StyledFooter,
         StyledContainer,
         StyledLinks,
         StyledLink,
         StyledPartners } from './Footer.styled';
import { Text } from '@movember/mo-gel';
import { ReactComponent as BtiLogo } from '../../images/footer/BTI_logo_2020_white.svg';
import MovemberFundedLogo from '../../images/footer/movember-funded-logo-white.png';

export const Footer = (props: any) => {

    return (
        <StyledFooter>
            <StyledContainer>
                <StyledLinks>
                    <StyledLink to="/gethelp">
                        <Text tag="p" level="normal" marginTop="0" marginBottom="0" fontWeight="bold">
                            Get help
                        </Text>
                    </StyledLink>
                    <StyledLink to="/contact">
                        <Text tag="p" level="normal" marginTop="0" marginBottom="0" fontWeight="bold">
                            Contact
                        </Text>
                    </StyledLink>
                    <StyledLink to="/terms">
                        <Text tag="p" level="normal" marginTop="0" marginBottom="0" fontWeight="bold">
                            Terms &amp; Conditions
                        </Text>
                    </StyledLink>
                    <StyledLink to="/privacy">
                        <Text tag="p" level="normal" marginTop="0" marginBottom="0" fontWeight="bold">
                        Privacy Statement
                        </Text>
                    </StyledLink>
                </StyledLinks>
                <StyledPartners>
                    <BtiLogo />
                    <img src={MovemberFundedLogo} alt="Movember Logo" />
                </StyledPartners>
            </StyledContainer>
            <Text tag="p" level="xxSmall" marginTop="0" marginBottom="0" align="center">
                © 2021 Movember. All rights reserved. Movember Canada is a registered charity number 84821 5604 RR0001
            </Text>
        </StyledFooter>
    );
};