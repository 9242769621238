import React, { Component } from 'react'
import { Partner } from './Partner'
import { partnerList }  from '../../contents/partners'

import { 
    StyledNavigationList,
    StyledHeading,
    StyledPartnerSection,
    StyledLogoContainer
  } from './PartnerList.styled'; 

export class PartnerList extends Component {

    render() {
        var items:any = []
        partnerList.forEach(function(partner) {
            items.push(<Partner partnerType={partner.partnerType} url={partner.url} logo={partner.logo}/>)
        })


        return(
            <StyledPartnerSection>
                <StyledHeading>Partners</StyledHeading>
                    <StyledNavigationList>
                        <StyledLogoContainer>
                            {items}
                        </StyledLogoContainer>
                    </StyledNavigationList>
            </StyledPartnerSection>
        );
    }

}

