import React from 'react';
import {
    StyledContainer, StyledHeroContainer, StyledHeroCallToAction
} from './HeroVideo.styled';
import { CallToActionComponent } from '../CallToAction/CallToAction';

export const HeroVideo = (props: any) => {
    return (
        <StyledContainer>
            <StyledHeroContainer autoPlay={true} loop={true} muted={true} playsinline={true} src={process.env.PUBLIC_URL + '/heroVideo.mp4'}>
                <source src={process.env.PUBLIC_URL + '/heroVideo.mp4'} type="video/mp4" />
                <source src={process.env.PUBLIC_URL + '/heroVideo.mp4'} type="video/ogg" />
                Your browser does not support the video tag.
            </StyledHeroContainer>
            <StyledHeroCallToAction>
                <CallToActionComponent callToAction={props.callToAction} buttonWidth="165px" headingLevel="h1"/>
            </StyledHeroCallToAction>
        </StyledContainer>
    );
};