import React from 'react';
import { Heading, Button } from '@movember/mo-gel';
import { ReactComponent as LeftArrow } from '../../../images/icons/left_icon.svg';
import { StyledContainer, StyledButtonContents } from './ContactUsConfirmation.styled';

export const ContactUsConfirmation = (props: any) => {
    const { isSubmitted, onNavigateBack } = props;
    return (
        <>
            {
                isSubmitted && 
                <StyledContainer>
                    <Heading level="h4" color="black">
                        We’ve got your message! One of our team will be in contact shortly.
                    </Heading>
                    <Button
                        onClick={
                            () => onNavigateBack(false)
                        }
                        >
                        <StyledButtonContents>
                            <LeftArrow />Go back
                        </StyledButtonContents>
                    </Button>
                </StyledContainer>
            }
        </>
    );
};