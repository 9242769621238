import styled, { css } from 'styled-components';
import { Button } from '@movember/mo-gel';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 81px 0 80px 0;
    background: ${(props: any) => props.theme.colors.primaryBrand2};
    position: relative;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        justify-content: flex-start;
        padding: 98px 0 106px 0;
        height: 711px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        height: auto;
        padding: 120px 248px 119px 247px;
    }
`;

export const StyledTextContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 0 15px 34px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        padding: 0 159px;
        justify-content: space-around;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        width: 319px;
        padding: 0;
        align-items: flex-start;
        flex-grow: 1;
        text-align: left;
    }
`;

export const StyledHeadingContainer = styled.div`
    white-space: nowrap;
`;

export const StyledButton = styled(Button)`
    height: 50px;

    :hover {
      ${(props: any) =>
        props.hoverColor ? css`
          color: ${props.theme.colors.primaryBrand1};
          background-color: ${props.hoverColor};
          svg {
            path {
              fill: ${props.theme.colors.primaryBrand1};
            }
          }
        ` : css`
          background-image: linear-gradient(to right, ${props.theme.colors.primaryBrand1}, ${props.theme.colors.primaryBrand3});
        `
      }
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        position: absolute;
        left: auto;
        right: auto;
        bottom: 40px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        top: 250px;
        left: 247px;
    }
`;

export const StyledButtonContents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;