import styled from 'styled-components';
import { BannerImage } from '../../typings/BannerImage.type';

const setBackgroundImage = (heroBanners: BannerImage[], theme: any) => {
  const breakpoints = theme.breakpoints;
  const cssValue: string[] = [];
  Object.keys(breakpoints).forEach((breakpoint) => {
    const bannerImage = heroBanners.find((heroBanner: BannerImage) => heroBanner.breakpoint === breakpoint);
    if (bannerImage) {
      if (breakpoint === 'xxs') {
        cssValue.push(`
          background-image: url(${bannerImage.bannerUrl});
          min-height: ${bannerImage.height}px;
      `);
      } else {
        cssValue.push(`
          @media screen and (min-width: ${breakpoints[breakpoint]}) {
            background-image: url(${bannerImage.bannerUrl});
            min-height: ${bannerImage.height}px;
          };
        `);
      }
    }
  });
  return cssValue;
};

export const StyledHeroBanner = styled.div`
  width: 100%;
`;

export const StyledHeroImage = styled.div`
  ${(props: any) => setBackgroundImage(props.heroBanners, props.theme)};
  background-position: 0 30%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
`;

export const StyledHeroCallToAction = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  margin-top: -180px;
  left: 50%;
  width: 80%;

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.xs}) {
    width: 50%;
  };

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    width: 100%;
  };

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.md}) {
    margin-top: -300px;
    width: 100%;
  };
  
  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
    text-align: left;
    left: 25%;
    width: 30%;
  };
`;
