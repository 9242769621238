import styled from 'styled-components';
import { Heading } from '@movember/mo-gel';

export const StyledMainContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 75px 15px 80px;
  text-align: left;

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 78px 41px 86px;
  }

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
    padding: 145.7px 150px 154.3px;
  }
`;

export const StyledActionContainer = styled.div`
  width: 100%;
  margin-bottom: 95px;

  p {
      margin-bottom: 30px
  }

  button {
      height: 50px;
  }

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    margin-bottom: 70px;
    width: 45%;
    p {
        margin-bottom: 20px;
    }
  }

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
    width: 45%;
    p {
        margin-bottom: 20px;
    }
  }  
`;

export const StyledMainHeading = styled(Heading)`
    margin-bottom: 27px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        margin-bottom: 36.5px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        margin-bottom: 59px;
    }
`;

export const StyledContentHeading = styled(Heading)`
    margin-bottom: 17px;
    text-align: left;
    text-transform: uppercase;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        margin-bottom: 13px;
    }
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
    text-align: left;
    white-space: pre-wrap;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        align-items: flex-start;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        align-items: flex-start;
    }
`;

export const StyledButtonContents = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-weight: 300;
`;

export const StyledIcon = styled.div`
    height: 100px;
    img {
        height: 100px;
    }
    padding-bottom: 20px;
`;