import styled from 'styled-components';

export const ImageContainer = styled.div`
  height: 200px;
  width 200px;
  margin: 0 auto;
  display: relative;
  justify-content: center;
  align-items: center;
`;

export const StyledImage = styled.img`
  vertical-align: middle;
  position: relative;
  height: 160px;
  width: 200px;
  cursor: pointer;
  object-fit: contain;
  padding: 20px 0;
`;
