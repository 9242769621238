import React, { Component } from 'react';
import { ReactComponent as Circle } from '../../../images/icons/circle.svg'
import { StyledIcon } from './Dot.styled'



export class Dot extends Component <any, any>{

    render() {
        return(
            <StyledIcon isActive={this.props.isActive}>
                <Circle />
            </StyledIcon>
        )
    }

}
