import React, { Component } from 'react'
import { ImageSlide } from '../Carousel/ImageSlide'
import { CarouselPrevArrow, CarouselNextArrow } from './CarouselArrow'
import { partnerList } from '../../../contents/partners'
import { PartnerType } from './PartnerType'

import { CarouselContainer, StyledHeading, CarouselSection } from './Carousel.styled'

interface MyProps {

}

interface MyStates {
    currentSlide: number,
}

export class Carousel extends Component<MyProps, MyStates> {
    constructor(props: any) {
        super(props);

        this.state = {
            currentSlide: 0,
        };

        this.previousSlide = this.previousSlide.bind(this)
        this.nextSlide = this.nextSlide.bind(this)
    }

    previousSlide() {
        const lastIndex = partnerList.length - 1;
        const { currentSlide } = this.state;
        const shouldResetIndex = currentSlide === 0
        const index = shouldResetIndex ? lastIndex : currentSlide -1;
        
        this.setState({
            currentSlide: index,
        });

    }

    nextSlide() {
        const lastIndex = partnerList.length -1;
        const { currentSlide } = this.state;
        const shouldResetIndex = currentSlide === lastIndex;
        const index = shouldResetIndex ? 0 : currentSlide +1;

        this.setState({
            currentSlide: index
        });
    }
    
    render() {
        return (
            <CarouselSection>
                <CarouselContainer>
                    <StyledHeading>Partners</StyledHeading>
                    <CarouselPrevArrow
                    clickFunction={this.previousSlide}/>
                    <ImageSlide 
                    url={partnerList[this.state.currentSlide].url}
                    logo={partnerList[this.state.currentSlide].logo}
                    total={partnerList.length}
                    activeIndex={this.state.currentSlide}/>
                    <CarouselNextArrow
                    clickFunction={this.nextSlide}/>
                    <PartnerType type={partnerList[this.state.currentSlide].partnerType}/>
                </CarouselContainer>
            </CarouselSection>
        )
    }
}