import React, { Component } from 'react';
import { Heading, Text } from '@movember/mo-gel';
import { ReactComponent as RightArrow } from '../../images/icons/right_icon.svg';
import { ReactComponent as LeftArrow } from '../../images/icons/left_icon.svg';
import { HalfImageHalfTextWithExtendedProps } from './HalfImageHalfTextWithExtendedProps';
import {
         StyledSlideContainer,
         StyledImageContainer,
         StyledImage,
         StyledImagePlaceholder,
         StyledTextContainer,
         StyledTitleContainer,
         StyledDescriptionContainer,
         StyledIcon
} from './HalfImageHalfText.styled';

export interface Slide {
  image: string;
  heading: string;
  subHeading: string;
  link?: string;
}

export interface HalfImageHalfTextProps {
  slides: Slide[];
  layout?: string;
}

export class HalfImageHalfText extends Component<HalfImageHalfTextProps, any> {
  constructor(props: HalfImageHalfTextProps) {
    super(props);

    this.state = {
      currentSlide: 1
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(value: number) {
    this.setState({ 
      currentSlide: this.state.currentSlide + value
    });
  }

  render() {
    const { slides } = this.props;

    return (
      <HalfImageHalfTextWithExtendedProps layout={this.props.layout}>
        <StyledSlideContainer>
          <StyledTextContainer>
            <StyledTitleContainer>
              <Heading level="h3" color="black">
                {slides[this.state.currentSlide - 1].heading}
              </Heading>
            </StyledTitleContainer>
            <StyledDescriptionContainer>
              <Text as="p" display="block" color="black">
                {slides[this.state.currentSlide - 1].subHeading}{slides[this.state.currentSlide - 1].link ? <a href="https://movember.com">{slides[this.state.currentSlide - 1].link}</a> : <></>}
              </Text>
            </StyledDescriptionContainer>
          </StyledTextContainer>
          <StyledImageContainer>
            {
              slides[this.state.currentSlide - 1].image ? <StyledImage image={slides[this.state.currentSlide - 1].image} /> : <StyledImagePlaceholder /> 
            }
          </StyledImageContainer>
        </StyledSlideContainer>
        <StyledIcon  direction="left">
            { this.state.currentSlide > 1 ? <LeftArrow onClick={() =>  this.handleClick(-1) } /> : <></>}
        </StyledIcon>
        <StyledIcon  direction="right">
            { slides.length > 1 && this.state.currentSlide < slides.length ? <RightArrow onClick={() =>  this.handleClick(1) } /> : <></>}
        </StyledIcon>
      </HalfImageHalfTextWithExtendedProps>
    );
  }
}