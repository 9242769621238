import styled from 'styled-components';

export const CarouselDotsContainer = styled.div`
    vertical-align: middle;        
    display: flex;
    align-items: center;
    justify-content: center ;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 25px;


`;