import React, { Component } from 'react';
import { CarouselDotsContainer } from './CarouselDots.styled'
import { Dot } from '../Carousel/Dot'




export class CarouselDots extends Component <any, any>{
    render() {
        const dotsArray = new Array(this.props.total).fill(0, 0, 3);

        return(
            <CarouselDotsContainer>
                {
                    dotsArray.map((value: any, index: any) => {
                        return(
                            <Dot isActive={index === this.props.activeIndex} />
                        )
                    })
                }
            </CarouselDotsContainer>
        )
    }
}
