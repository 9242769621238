import React, { Component } from 'react';

import DiagonalSplitImage from '../../images/page_banners/Banner_GetHelp.png';
import DiagonalSplitImageTablet from '../../images/page_banners/Banner_GetHelp_Tablet.png';
import DiagonalSplitImageMobile from '../../images/page_banners/Banner_GetHelp_Mobile.png';
import { StyledGetHelpPage } from './GetHelp.styled';
import { DiagonalSplitBanner } from '../../components/DiagonalSplitBanner/DiagonalSplitBanner';
import { Size } from '../../typings/Size.type';
import { GetHelpContent } from '../../contents/getHelp';
import { GetHelp } from '../../components/GetHelp/GetHelp';

export class GetHelpPage extends Component {
    render() {
        return (
        <StyledGetHelpPage>
            <DiagonalSplitBanner callToAction={GetHelpContent} images={[DiagonalSplitImage, DiagonalSplitImageTablet, DiagonalSplitImageMobile]} size={Size.small} />
            <GetHelp />
        </StyledGetHelpPage>
        );
    }
}