import styled from 'styled-components';

export const StyledAccordion = styled.div`
`;

export const StyledAccordionItem = styled.div`
`;

export const StyledAccordionItemHeader = styled.div`
  cursor: pointer;
`;

export const StyledAccordionItemHeaderOpen = styled(StyledAccordionItemHeader)`
`;

export const StyledAccordionItemHeaderClosed = styled(StyledAccordionItemHeader)`
`;

export const StyledAccordionItemContent = styled.div`
  display: ${(props: any) => props.isOpen ? 'block' : 'none'};
`;