export const colors = {
    white: '#ffffff',
    black: '#000000',
    primaryBrand1: '#006af9',
    primaryBrand2: '#e5f0fd',
    primaryBrand3: '#66a6fb',
    primaryBrand4: '#66a6fb',
    secondaryBrand1: '#d7d8de',
    secondaryBrand2: '#eeeeee',
    secondaryBrand3: '#f2f5f7',
    primaryFont: '#f6f6f6',
    content: '#0c1e2c',
    accentFont: '#4ba1e9',
    error: '#ff3838',
  
    // warning colors: orange and yellow
    warning1: '#ff8500',
    warning2: '#ffdd00',
  
    // darkest/worse (1) to lightest/best (3)
    success1: '#7ac121',
    success2: '#7ad521',
    success3: '#7aeb21'
  };
