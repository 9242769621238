import styled from 'styled-components';
import { Heading } from '@movember/mo-gel';

export const CarouselSection = styled.div`
  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    display: none;
  }
  background-color: ${(props: any) => props.theme.colors.primaryBrand2};
  width: 100%;
  height: 360px;
  padding: 40px 0;
`;

export const CarouselContainer = styled.div`
  justify-content: center;
  align-items: center;    
  vertical-align: middle;        
  display: flex;
  flex-wrap: wrap
  width: 300px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (width: ${(props: any) => props.theme.breakpoints.sm}) {
    display: flex;
  }

`;

export const StyledHeading = styled(Heading)`
  color: ${(props: any) => props.theme.colors.black};
  justify-content: center;
  align-items: center;
  font-size: 35px;
  text-align: center;
  width:100%;

`;