import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Text, Button } from '@movember/mo-gel';
import { StyledMainContainer,
         StyledMainHeading,
         StyledContentMainContainer,
         StyledContentContainer,
         StyledContentHeading,
         StyledImage,
         StyledContent,
         StyledButtonContents,
         StyledIconContainer,
         StyledChevron } from './WhoIsThisFor.styled';
import { ReactComponent as RightArrow } from '../../images/icons/right_icon.svg';
import { slides } from '../../contents/whoisthisfor';

class WhoIsThisFor extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            currentSlide: 0,
            marginLeft: 0
        };
    }

    handleClick(value: number) {
        const { marginLeft } = this.state;
        let newMargin = 0;

        if (marginLeft === -200 && value === -100) {
            newMargin = 0;
        } else if (marginLeft === 0 && value === 100) {
            newMargin = -200;
        } else {
            newMargin = this.state.marginLeft + value;
        }

        this.setState({
            marginLeft: newMargin
        });
    }

    renderSlides() {
        return slides.map((slide: any, index: number) =>
            <StyledContentContainer key={index}>
                <StyledImage image={ slide.image } />
                <StyledContent>
                    <StyledContentHeading level="h5" align="center" marginTop="0" color={this.props.theme.colors.content}>
                        { slide.title }
                    </StyledContentHeading>
                    <Text tag="p" level="normal" marginBottom="0" color={this.props.theme.colors.content}>
                        { slide.text }
                    </Text>
                </StyledContent>
                <Button 
                    primary={true}
                    width="194px"
                    onClick={() => this.props.history.push(slide.link)}
                    >
                    <StyledButtonContents>
                        Find out more <RightArrow/>
                    </StyledButtonContents>

                </Button>
            </StyledContentContainer>
        );
    }

    render() {
        return (
            <StyledMainContainer id="whositfor">
                <StyledMainHeading level="h4" align="center" marginTop="0" width="100%" color={this.props.theme.colors.content}>
                    WHO IS THIS FOR?
                </StyledMainHeading>
                <StyledContentMainContainer marginLeft={this.state.marginLeft}>
                    {
                        this.renderSlides()
                    }
                </StyledContentMainContainer>
                <StyledIconContainer>
                    <StyledChevron content="'<'" onClick={ () => this.handleClick(100) } />
                    <StyledChevron content="'>'" onClick={ () => this.handleClick(-100) } />
                </StyledIconContainer>
            </StyledMainContainer>
        );
    }
}

const component = withTheme(withRouter(WhoIsThisFor));

export { component as WhoIsThisFor };