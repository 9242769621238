import styled, { css } from 'styled-components';

export const StyledButtonContents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;

export const StyledCallToActionContainer = styled.div`
  white-space: pre-wrap;
  button {
    color: #ffffff;
    height: auto;

    :hover {
      ${(props: any) =>
        props.hoverColor ? css`
          color: ${props.theme.colors.primaryBrand1};
          background-color: ${props.hoverColor};
          svg {
            g {
              g {
                path {
                  fill: ${props.theme.colors.primaryBrand1};
                }
              }
            }
          }
        ` : css`
          background-image: linear-gradient(to right, ${props.theme.colors.primaryBrand1}, ${props.theme.colors.primaryBrand3});
        `
      }
    }
  }
`;