export const GetHelpContent = {
    heading: 'GET HELP',
    subHeading: `Everyone goes through tough times. The key is knowing how and when to get help. 
    
Whether you're an athlete, parent or coach, taking care of yourself and looking out for others is most important. 
    `
};

export const HelpContactInfo = [
    {
        heading: 'KIDS HELP PHONE',
        links: [
            'www.kidshelpphone.ca',
            '1-800-668-6868'
        ]
    },
    {
        heading: 'CANADIAN MENTAL HEALTH ASSOCIATION ONTARIO',
        links: [
            '1-866-531-2600',
            'www.ontario.cmha.ca '
        ]
    }
];