import React, { Component } from 'react';
import { StyledAccordion,
        StyledAccordionItem,
        StyledAccordionItemHeaderOpen,
        StyledAccordionItemHeaderClosed,
        StyledAccordionItemContent
      } from './Accordion.styled';

export interface AccordionItem {
  header: React.ReactNode,
  content: React.ReactNode
}

export interface AccordionProps {
  allowMultipleOpen: boolean
  items: AccordionItem[]
  openItems?: AccordionItem[]
}

export interface AccordionState {
  openItems: AccordionItem[]
}

export class Accordion extends Component<AccordionProps, AccordionState> {
  constructor(props : any){
    super(props);

    this.state = {
      openItems: props.openItems || []
    }

    this.toggleItem = this.toggleItem.bind(this);
  }
  toggleItem(item : AccordionItem) {
    let { openItems } = this.state;
    let isOpen = openItems.includes(item);

    if(isOpen) {
      this.setState({
        openItems: [...openItems.filter(i => i !== item)]
      })
    } else {
      //If multiple are not allowed, we only want this item open
      this.setState({
        openItems: this.props.allowMultipleOpen
          ? [...openItems, item]
          : [item]
      });
    }
  }
  render() {
    let { items } = this.props;
    let { openItems } = this.state;

    return <StyledAccordion {...this.props}>
      {items.map(item => {
        let isOpen = openItems.includes(item);
        
        return <StyledAccordionItem>
          {isOpen
            ? <StyledAccordionItemHeaderOpen onClick={() => this.toggleItem(item)}>{item.header}</StyledAccordionItemHeaderOpen>
            : <StyledAccordionItemHeaderClosed onClick={() => this.toggleItem(item)}>{item.header}</StyledAccordionItemHeaderClosed>
          }
          <StyledAccordionItemContent isOpen={isOpen}>{item.content}</StyledAccordionItemContent>
        </StyledAccordionItem>;
      })}
    </StyledAccordion>
  }
}