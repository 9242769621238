import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Text, Button } from '@movember/mo-gel';
import { ReactComponent as RightArrow } from '../../images/icons/right_icon.svg';
import { StyledMainContainer,
         StyledContentHeading,
         StyledContent,
         StyledIcon,
         StyledActionContainer,
         StyledButtonContents } from './TwoColumnTiles.styled';
import { researchPanels } from '../../contents/research';

class TwoColumnTiles extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            currentSlide: 0,
            marginLeft: 0
        };
    }

    
    openLinkInNewTab = (url: string) => {
        window.open(url, "_blank")
    }    

    renderSlides(items: any) {
        return items.map((panel: any, index: number) =>
            <StyledActionContainer key={index}>
                <StyledContent>
                    <StyledIcon>
                        <img src={panel.image} alt="Research"></img>
                    </StyledIcon>
                    <StyledContentHeading level="h5" align="center" marginTop="0" color={this.props.theme.colors.content}>
                        { panel.title }
                    </StyledContentHeading>
                    <Text tag="p" level="normal" marginBottom="0" color={this.props.theme.colors.content}>
                        { panel.text }
                    </Text>
                </StyledContent>
                { panel.link !== '' && (
                    <Button
                    primary={true}
                    width="244px"
                    onClick={() => this.openLinkInNewTab(panel.link)}
                    >
                        <StyledButtonContents>
                            { panel.linkText }<RightArrow/>
                        </StyledButtonContents>            
                    </Button>
                )}
            </StyledActionContainer>
        );
    }

    render() {
        return (
            <StyledMainContainer>
                {
                    this.renderSlides(researchPanels)
                }   
            </StyledMainContainer>
        );
    }
}

const component = withTheme(withRouter(TwoColumnTiles));

export { component as TwoColumnTiles };